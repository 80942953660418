/* =========== SPINNER AND CIRCLE ========== */
.spinner {
  width: 15rem;
  animation: spin 1.5s cubic-bezier(0.45, 0.05, 0.55, 0.95) infinite;
}

@keyframes spin {
  0% {
    transform: scale(0.1) rotate(0deg);
  }
  10% {
    transform: scale(0.2) rotate(36deg);
  }
  20% {
    transform: scale(0.3) rotate(72deg);
  }
  30% {
    transform: scale(0.4) rotate(108deg);
  }
  40% {
    transform: scale(0.5) rotate(144deg);
  }
  50% {
    transform: scale(0.6) rotate(180deg);
  }
  60% {
    transform: scale(0.7) rotate(216deg);
  }
  70% {
    transform: scale(0.8) rotate(252deg);
  }
  80% {
    transform: scale(0.5) rotate(288deg);
  }
  90% {
    transform: scale(0.2) rotate(324deg);
  }
  100% {
    transform: scale(0) rotate(360deg);
    opacity: 0.7;
  }
}

@keyframes grow {
  0% {
    transform: scale(0.92);
  }
  25% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.92);
  }
  75% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.92);
  }
}

@keyframes grow2 {
  0% {
    width: 15rem;
    height: 15rem;
  }
  100% {
    width: 15rem;
    height: 15rem;
  }
}
/* =========== VOICE WAVE FORM ========== */
@keyframes quiet {
  25% {
    transform: scaleY(0.6);
  }
  50% {
    transform: scaleY(0.4);
  }
  75% {
    transform: scaleY(0.8);
  }
}

@keyframes normal {
  25% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0.4);
  }
  75% {
    transform: scaleY(0.6);
  }
}
@keyframes loud {
  25% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0.4);
  }
  75% {
    transform: scaleY(1.2);
  }
}

.box {
  animation-duration: 1.2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.box1 {
  animation-name: quiet;
}

.box2 {
  animation-name: normal;
}

.box3 {
  animation-name: quiet;
}

.box4 {
  animation-name: loud;
}

.box5 {
  animation-name: quiet;
}

